import React from "react";
import PageHeader from "views/sections/PageHeader/PageHeader";
import SessionSummary from "views/sections/SessionSummary/SessionSummary";
import PageResultsWithData from "containers/PageResultsWithData";
import Alert from "views/components/Alert/Alert";

import "./Sessions.css";

class Sessions extends React.Component {
  render() {
    const {
      page,
      title,
      showFilters = true,
      showCompany = false,
      visitorId,
      params
    } = this.props;

    return (
      <div
        className="container"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <PageHeader title={title} />

        <SessionSummary
          page={page}
          title="Sessions"
          params={params}
          showFilters={showFilters}
          showCompany={showCompany}
          visitorId={visitorId}
          showBorder
        />

        <PageResultsWithData page={page} params={params} />
      </div>
    );
  }
}

export default Sessions;
