import ActivitiesContainer from "containers/ActivitiesContainer";
import AnalyticsContainer from "containers/AnalyticsContainer";
import AnalyticsDurationDepthContainer from "containers/AnalyticsDurationDepthContainer";
import ChartHeroContainer from "containers/ChartHeroContainer";
import React from "react";
import audienceTableFactory from "utils/tableFactories/audienceTableFactory";
import ChartCard from "views/components/ChartCard/ChartCard";
import TopCountCard from "views/components/TopCountCard/TopCountCard";
import Alert from "views/components/Alert/Alert";
import audienceKeys from "views/pages/Audience/data/audienceKeys";
import ChartHero from "views/sections/ChartHero/ChartHero";
import PageHeader from "views/sections/PageHeader/PageHeader";
import HvActionsTableSection from "views/sections/TableSection/HvActionsTableSection";
import TableSection from "views/sections/TableSection/TableSection";
import "./Home.scss";
import { Export } from "views/components/Export/Export";

class Home extends React.Component {
  render() {
    const page = this.props.page || `home`;

    return (
      <div
        className="container"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <PageHeader title="WebTrax Home" />

        <ChartHeroContainer page={page}>
          {({ data, loading }) => {
            return (
              <ChartHero
                title="Top Referrers"
                data={data}
                loading={loading}
                link
              />
            );
          }}
        </ChartHeroContainer>

        <div className="section-header--with-export">
          <h2>Your Most Active Identifiable Audience</h2>

          <div className="results-control">
            <div className="results-control__utility-icons">
              <Export page={page} downloadType="ACTIVE_AUDIENCES" />
            </div>
          </div>
        </div>

        <TableSection
          tableCols={audienceKeys}
          contentFactory={audienceTableFactory}
          audienceDashboardButton
          link={true}
          page={page}
        />

        <ActivitiesContainer
          page={page}
          limit={10}
          offset={0}
          defaultFilters={["lead"]}
        >
          {({ data }) => {
            if (!data || !data.edges.length) {
              return null;
            }
            return (
              <React.Fragment>
                <HvActionsTableSection
                  data={data}
                  showLink="/sessions/actions"
                  hideFilters
                  hidePagination
                  hideEmptyComponent
                  homepage
                />
              </React.Fragment>
            );
          }}
        </ActivitiesContainer>

        <h2>Your Key Analytics</h2>
        <section className="card-group home-cards">
          <AnalyticsContainer
            queryKey="analytics-referrers"
            page={page}
            hardLimit={5}
          >
            {({ data, loading }) => {
              return (
                <TopCountCard
                  title="Top Referrers"
                  link={"/analytics/referrers"}
                  data={data}
                  itemProp="url"
                  valueProp="sessionsCount"
                  loading={loading}
                />
              );
            }}
          </AnalyticsContainer>

          <AnalyticsDurationDepthContainer page={page} standardSort>
            {({ durations, depths, loading }) => {
              return (
                <React.Fragment>
                  <ChartCard
                    title="Session Depth"
                    data={[
                      {
                        id: "Sessions:",
                        color: "#3cb4e8",
                        data: depths.map(d => {
                          return {
                            x: d.node.depth,
                            y: d.node.sessionsct
                          };
                        })
                      }
                    ]}
                    loading={loading}
                    customToolTip="depth"
                  />
                  <ChartCard
                    title="Session Length"
                    data={[
                      {
                        id: "Sessions:",
                        color: "#3cb4e8",
                        data: durations.map(d => {
                          return {
                            x: d.node.durationRange,
                            y: d.node.sessionsct
                          };
                        })
                      }
                    ]}
                    loading={loading}
                    customToolTip="length"
                  />
                </React.Fragment>
              );
            }}
          </AnalyticsDurationDepthContainer>
        </section>
      </div>
    );
  }
}

export default Home;
