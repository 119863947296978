import React from "react";

import PageHeader from "views/sections/PageHeader/PageHeader";
import AnalyticsPageResults from "views/sections/AnalyticsPageResults/AnalyticsPageResults";

import "./Analytics.css";

import pageConfig from "./pageConfig";
import Alert from "views/components/Alert/Alert";
// import { SSL_OP_NETSCAPE_DEMO_CIPHER_CHANGE_BUG } from "constants";

class Analytics extends React.Component {
  render() {
    const { page, title, referrer } = this.props;

    const newref = referrer && referrer["ref"];

    return (
      <div
        className="container"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <PageHeader title={title} />

        <AnalyticsPageResults
          page={page}
          keys={pageConfig[page].keys}
          tableFactory={pageConfig[page].tableFactory}
          mock={pageConfig[page].data ? pageConfig[page].data : null}
          openKey={pageConfig[page].openKey || ""}
          detailsTitle={pageConfig[page].detailsTitle || (() => "Details")}
          detailsComponent={pageConfig[page].detailsComponent}
          title={
            newref
              ? `${pageConfig[page].pageContent} ${newref}`
              : pageConfig[page].pageContent
          }
          backToLink={
            page === "analytics-referrer" && {
              label: "all referrers",
              nav: "/analytics/referrers"
            }
          }
          referrer={newref}
          defaultSort={pageConfig[page].sort || []}
        />
      </div>
    );
  }
}

export default Analytics;

// target dropdown DONT SHOW "OTHER"
